import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout.js";
import Seo from "../components/seo"
import HeroTitle from "../components/hero-title/hero-title"
import $ from 'jquery'

class PageTemplate extends Component {
	componentDidMount() {
		$('.header-links .header-link').each(function() {
			if($(this).attr('href') === window.location.pathname) {
				$(this).addClass('active')
			}
		});
	}

	render() {
		const currentPage = this.props.data.wpPage
		const allNews = this.props.data.allWpNewsArticle
		var newsArr = [];
		if(allNews) {
			newsArr = allNews.edges
		}

		return (
			<Layout>
				<Seo
					title={currentPage.title}
					contentBody={currentPage.body}
					locationOrigin={this.props.location.origin + currentPage.uri}
					domen={this.props.location.origin}
					image={currentPage.featuredImage ?  currentPage.featuredImage.node.sourceUrl.replace(process.env.WP_SITE_URL,"/") : null}
				/>
				<header className="master-header video-header">
					{currentPage.headerBackground !== false && currentPage.headerBackground.vimeoId !== false ? (
						<>
							<img  src={`/rings.svg`} alt="loader" className="loader" />
							<iframe src={'https://player.vimeo.com/video/' + currentPage.headerBackground.vimeoId + '?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" class="fade-in'} title="vimeo background"></iframe>
							{/*
								<div className="container">
									<div className="page-header">
										<h1 className="page-title">{currentPage.title}</h1>
									</div>
								</div>
							*/}
						</>
					) : (
						null
					)}
				</header>
				{currentPage?.title &&
					<HeroTitle title={currentPage?.title} />
				}
				<section className="main-content">
					<div className="container">
						{currentPage.sidebarWidgetRelation !== null ? (
							<div className="uk-grid" data-uk-grid>
								<div className="uk-width-2-3@m main-col">
									{newsArr.map((news, index) => (
										<div className="latest-news" key={index}>
											<div className="news-item">
												<h3>{news.node.title}</h3>
												<div dangerouslySetInnerHTML={{__html: news.node.excerpt}} />
												<Link className="read-more-link" to={news.node.uri}>Read more &gt;&gt;</Link>
											</div>
										</div>
									))}
									{currentPage.moreNews && currentPage.moreNews.moreNewsLink ? (
										<a className="more-news-link" href={currentPage.moreNews.moreNewsLink.url} target="_blank" rel="noreferrer">{currentPage.moreNews.moreNewsLink.title}</a>
									) : (
										null
									)}
								</div>
								<div className="uk-width-1-3@m">
									{currentPage.sidebarWidgetRelation.sidebarWidgetRelations.map((widget, index) => (
										<div className="operations-box" dangerouslySetInnerHTML={{__html: widget.content}} key={index} />
									))}
								</div>
							</div>
						) : (
							<div className="main-col">
								{newsArr.map((news, index) => (
									<div className="latest-news" key={index}>
										<div className="news-item">
											<h3>{news.node.title}</h3>
											<div dangerouslySetInnerHTML={{__html: news.node.excerpt}} />
											<Link className="read-more-link" to={news.node.uri}>Read more &gt;&gt;</Link>
										</div>
									</div>
								))}
								{currentPage.moreNews && currentPage.moreNews.moreNewsLink ? (
									<a className="more-news-link" href={currentPage.moreNews.moreNewsLink.url} target="_blank" rel="noreferrer">{currentPage.moreNews.moreNewsLink.title}</a>
								) : (
									null
								)}
							</div>
						)}
					</div>
				</section>
			</Layout>
		)

	}
}

export default PageTemplate

export const pageQuery = graphql`
	query($id: String!) {

		wpPage(id: { eq: $id }) {
			uri
			title
			slug
			status
			content
			featuredImage {
				node {
					sourceUrl
				}
			}
			headerBackground {
				vimeoId
				videoPoster {
					sourceUrl
				}
			}
			moreNews {
				moreNewsLink {
					title
					url
					target
				}
			}
			sidebarWidgetRelation {
				sidebarWidgetRelations {
					... on WpSidebarWidgets {
						title
						content
					}
				}
			}
		}

		allWpNewsArticle(limit: 8, sort: {fields: date, order: DESC}) {
			edges {
				node {
					date(difference: "month")
					title
					excerpt
					uri
					status
				}
			}
		}

		site {
			id
			siteMetadata {
				title
			}
		}

	}
`

