import React from "react";
import './hero-title.scss';


function HeroTitle({title}) {

	return (
		<div>
			<div className="container">
				<div className="hero-title-container">
					<h1>{title}</h1>
				</div>
			</div>
		</div>
	);
}

export default HeroTitle;
